import React from 'react';
import { StyleSheet, Text, View, Image, ImageBackground } from 'react-native';

import {MaterialToast} from './MaterialToast';

export function HomeMarquee() {
  return (
    <View style={styles.rect}>
      <ImageBackground
        source={require("../../assets/images/home_slider1_1900X1080.jpg")}
        resizeMode="cover"
        style={styles.image}
      >
        <View style={[styles.materialToast]}>
          <Text style={styles.toastMessage}>
            TEAM WORK{"\n"}
            {"\n"}Part Of Our Core Fundamentals
            </Text>
        </View>    
      </ImageBackground>
    

    </View>
  );
}

const styles = StyleSheet.create({
  rect: {
    flexDirection: "column",
    flex: 1,
    justifyContent:"center",
    padding:10,
    borderColor:'black',
  },
  image: {
    flex:1,
    width: '100%',
    height: '80%',
    minWidth: '100%',
    minHeight: 400,
  },
  image_imageStyle: {},
  materialToast: {
    backgroundColor: '#B7C2C8',
    opacity: 0.80,
    minWidth: 288,
    width: '40%',
    height: '15%',
    alignSelf:"center",
    padding:5, 
    marginTop:300,
  },
  toastMessage: {
    width: 206,
    height: 57,
    color: 'black',
    justifyContent: "space-between",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    alignSelf: "center"
  }
});
