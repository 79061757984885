import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import {stylesCommon} from '../common/MyWebElements';
//import { Image } from 'react-native-elements';

export const cardMessage = [
	{
		cardTitle: 'Oracle Solutions',
		cardMessage: 'TEQNIK LTD is a UK based technology company providing expertise and consultancy in implementing and managing IT solutions.W ith our customer first approach, coupled with our core fundamentals like team work, innovation, agility and creativity to name some of them, we strive to ensure that our solutions are not only scalable and cost efficient but they result in a 360 degree customer satisfaction. We provide solutions across various techology stack ranging from cloud migration and integration, visualisations and insights driven by data, developing bespoke web based and desktop applications and implemeting scalable and enterprise level orchestration or middleware solutions.',
	},

]

export function About() {
  return (
      <View style={styles.container}>
		<View style={styles.rect}>
		<Image
		  source={ require('../../assets/images/about_teq1.png')}
		  style={styles.image}
		/>
		</View>		
		<View style={{width: 300, paddingVertical: 20}}>
			<Text style={stylesCommon.TextPageHeader}>About Us</Text>
			<Text style={stylesCommon.TextSectionContent, styles.aboutContent }>
				TEQNIK LTD is a UK based technology company providing expertise and consultancy in implementing and managing IT solutions. 
				{"\n"} {"\n"}		
				With our customer first approach, coupled with our core fundamentals like team work, innovation, agility and creativity to name some of them, we strive to ensure that our solutions are not only scalable and cost efficient but they result in a 360 degree customer satisfaction.
				{"\n"} {"\n"}
				We provide solutions across various techology stack ranging from cloud migration and integration, visualisations and insights driven by data, developing bespoke web based and desktop applications and implemeting scalable and enterprise level orchestration or middleware solutions.
			</Text>
		</View>
		
      </View>
    );
}

const styles = StyleSheet.create({
	container: {
		flex:1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'space-around',
	},
	rect: {
	    padding:10,
	  },
	
	image: {
	    width: 400,
	    height: 400,
		resizeMode: 'contain',
	  },
	aboutContent: {
		alignText: 'justify',
		paddingTop: 10,
		alignContent:'center'
	},
  card: {
        width: 380, 
        margin: '1%',
		aspect: 1,   
  },	
	
});
