import React from 'react';
import { StyleSheet, Text, View, Image, ImageBackground } from 'react-native';
import { MainCanvas } from './modules/teqnik-pages/MainCanvas';
import {LoadFonts} from './modules/common/MyWebElements';

export default function App() {
	
	LoadFonts();
	
	return (
		<View>
			<MainCanvas />
		</View>
	);
}

const styles = StyleSheet.create({

});
