import React from 'react';
import { StyleSheet,} from "react-native";
import * as Font from 'expo-font';

export const LoadFonts = () => {
	return (
		
		Font.loadAsync({
			'Aileron-Bold': require('../../assets/fonts/Aileron-Bold.otf'),
			'Aileron-Regular': require('../../assets/fonts/Aileron-Regular.otf'),
			'Aileron-Light': require('../../assets/fonts/Aileron-Light.otf'),
			'Aileron-Thin': require('../../assets/fonts/Aileron-Thin.otf'),			
		    })

	);

}

export const stylesCommon = StyleSheet.create({
	TextPageHeader: {
		fontFamily: 'Aileron-Light',
		fontSize: 50,
	},
	TextSectionHeader: {
		fontFamily: 'Aileron-Light',
		fontSize: 30,
	},
	TextSectionContent: {
		fontFamily: 'Aileron-Light',
		fontSize: 15,
	},


});