import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { StyleSheet, Text, View, Image } from 'react-native';
import {stylesCommon} from '../common/MyWebElements';
//import { Image } from 'react-native-elements';
import { Input, Button } from 'react-native-elements';

export const cardMessage = [
	{
		cardTitle: 'Oracle Solutions',
		cardMessage: 'TEQNIK LTD is a UK based technology company providing expertise and consultancy in implementing and managing IT solutions.W ith our customer first approach, coupled with our core fundamentals like team work, innovation, agility and creativity to name some of them, we strive to ensure that our solutions are not only scalable and cost efficient but they result in a 360 degree customer satisfaction. We provide solutions across various techology stack ranging from cloud migration and integration, visualisations and insights driven by data, developing bespoke web based and desktop applications and implemeting scalable and enterprise level orchestration or middleware solutions.',
	},

]

export function ContactUs() {
  return (
      <View style={styles.container}>

		<View style= {{padding: 30}}>
			
			<Text style={[stylesCommon.TextSectionHeader,{padding:30,}]}>
				Contact Us
			</Text>
			
			<Input
				label='Contact Name'
				leftIcon={{ type: 'font-awesome', name: 'user' }}
				leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
			/>
			<View style= {{padding: 5}}></View>
			<Input 
				label='Company Name'
				leftIcon={{ type: 'font-awesome', name: 'building' }}
				leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
			/>
			<View style= {{padding: 5}}></View>
			<Input
				label='Email Id'
				leftIcon={{ type: 'font-awesome', name: 'envelope' }}
				leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
			/>
			<View style= {{padding: 5}}></View>
			<Input
				label='Contact Telephone'
				leftIcon={{ type: 'font-awesome', name: 'phone-square' }}
				leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
			/>
			<View style= {{padding: 5}}></View>
			<Input
				label='Enquiry Details'
				multiline
				numberOfLines={4}
			/>
			<View style= {{padding: 5}}></View>

			
			<Button style= {{padding: 30}}
				title="Submit Enquiry"
				type="outline"
				
			/>
			
			
		</View>
		
      </View>
    );
}

const styles = StyleSheet.create({
	container: {
		flex:1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'center',
		alignContent: 'center',
	},

});
