import React from 'react';
import { StyleSheet, Text, View, } from 'react-native';
import {stylesCommon} from '../common/MyWebElements';

export function Footer() {
  return (
      <View style = {styles.footer}>
		<Text style={stylesCommon.TextSectionContent}>Copyright @ TEQNIK LTD 2017</Text>
      </View>
    );
}

const styles = StyleSheet.create({
	
  footer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
	justifyContent: 'center',
    backgroundColor: '#0FBED9',
  },
	

});
