import React from 'react';
import { StyleSheet, View, } from 'react-native';
import { HomeMarquee } from './HomeMarquee';
import { MaterialCardWithoutImage } from '../common/MaterialCardWithoutImage';

export const cardMessage = [
	{
		cardTitle: 'Oracle Solutions',
		cardMessage: 'We provide consultancy across varied Oracle solutions, ranging from functional & technical expertise around Oracle EBIZ & Fusion implementation; support for Oracle database solutions and integration of apps using Oracle Fusion Middleware.',
	},
	{
		cardTitle: 'Cloud Solutions',
		cardMessage: 'We can help businesses on their journey to either migrate their business applications to cloud (AWS, GCP or Azure) or integrate their inhouse applications with cloud based vendors using proven, industry accepted, scalable and secure design patterns.',
	},
	{
		cardTitle: 'Bespoke Solutions',
		cardMessage: 'We apprciate certain business requirements can be quite niche and bespoke. We can help develop business solutions across variety of technologies - from web development stack to java, python and similar application development platforms and frameworks.',
	},
	{
		cardTitle: 'Data Solutions',
		cardMessage: 'We understand how pivotal data is and how it can help transform your business - a small insight could have a potential of making significant gains. We can help bring data & analytics to life, not only from your business but surrounding factors affecting it. ',
	},

]

export function Home() {
  return (
      <View>
        <HomeMarquee />
        <View style={styles.cardView}>
          <MaterialCardWithoutImage style={styles.card} cardProp={cardMessage[0]} />
          <MaterialCardWithoutImage style={styles.card} cardProp={cardMessage[1]} />
          <MaterialCardWithoutImage style={styles.card} cardProp={cardMessage[2]} />
		  <MaterialCardWithoutImage style={styles.card} cardProp={cardMessage[3]} />
        </View>
      </View>
    );
}

const styles = StyleSheet.create({
  cardView: {
	flexDirection:"row",
    flexWrap: 'wrap',
	justifyContent: 'center',
	
  },
  card: {
        width: 380, 
        margin: '1%',
		aspect: 1,   
  },


});
