import React, {Component} from 'react';
import {useState} from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import { SideMenu } from './sidemenu';
import { Footer } from './Footer';
import { Icon } from 'react-native-elements';
import {stylesCommon} from '../common/MyWebElements';

export function MainCanvas() {

const [isOpen, toggleIsOpen] = useState(false)

const	toggleModal = () => {
		toggleIsOpen(!isOpen)		
	  }	

	  return (
		  <View>
			  <View style={{ flexDirection: 'row' , flex:1, paddingBottom: 15, }}>
			
						<View style={{flexDirection: 'row' , width: '100%',  height:50, backgroundColor: '#0FBED9', alignItems: 'center', justifyContent: 'center', }}>
							<View style={{position:'absolute', left: 10, top:15,}} > 
								<Icon  onPress={toggleModal}
		  							name='menu' />	
							</View>
              <Image  resizeMode='contain'
                style={{height:50, width:50}}
                source={require('../../assets/images/profile.png')}
              />
              <Text style={[stylesCommon.TextSectionHeader,{color:'#B7C2C8'}]}>
                Teqnik
              </Text>
							<View style={{position:'absolute', right: 80, top:15,}} > 
								<Icon  
                    name='facebook-square'
                    type='font-awesome'/>	
							</View>
							<View style={{position:'absolute', right: 50, top:15,}} > 
								<Icon  
                    name='twitter-square'
                    type='font-awesome'/>	
							</View>
							<View style={{position:'absolute', right: 20, top:15,}} > 
								<Icon  
                    name='linkedin'
                    type='font-awesome'/>	
							</View>

	{/*						<View style={{height: 50, backgroundColor: 'powderblue', flexDirection: 'row', alignItems: 'center'}}>		
						    	<Text style={{}}>teqnik</Text>
							</View>
							
							
					<View style={{flexDirection: 'row' , width: '99%',  backgroundColor: '#0FBED9',  alignItems: 'center'}} >
					</View>
					
					        <TouchableOpacity style={{position:'absolute', left: 10, top:15,}} onClick={toggleModal}>
					          <Text >Menu</Text>
					        </TouchableOpacity>
					


	*/}						
						</View>
					
			  </View>
				
			<SideMenu show={isOpen}
			          onClose={toggleModal}>
			</SideMenu>					
		
			<Footer style={{marginBottom: 5}}/>
		  </View>  


	);
}

const styles = StyleSheet.create({
  header: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    alignItems: 'center',
    backgroundColor: '#0FBED9',
  },
  logoView: {
    flex:-1,
    width: undefined,
    height: undefined,

  },
  logo: {
    height: 50,
    width: 100,
  },
  logoText: {
    fontSize:24,
    color: '#B7C2C8',
    fontWeight:"bold",
  },
  SideBarView: {
    flex:-1,
    width: undefined,
    height: undefined,
    justifyContent: "center",
    backgroundColor: '#0FBED9',

  },
  SideBarText: {
    paddingLeft:'0%',
    fontSize:15,
    color: '#B7C2C8',
    fontWeight:"bold",

  },
});
