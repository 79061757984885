import React from 'react';
import {NavigationActions} from 'react-navigation';
import { StyleSheet, ScrollView, Text, View } from 'react-native';
import { Home } from './Home';
import { About } from './About';
import { ContactUs } from './ContactUs';
import { EduLogin } from '../edu-teqnik/EduLogin';
import {stylesCommon} from '../common/MyWebElements';
import { ListItem } from 'react-native-elements'

//import { StackNavigator } from 'react-navigation';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


// ***********************************************************
// to be deleted
// boiler plate code
// ***********************************************************
const	 navigateToScreen = (route) => () => {

    const navigateAction = NavigationActions.navigate({
    routeName: route
    });
    navigation.dispatch(navigateAction);
}


// ***********************************************************


function HomeFunc() {
  return (
	<Home />
  );
}

function AbtFunc() {
  return (
	<About />
  );
}

function ContactUsFunc() {
	return (
	  <ContactUs />
	);
  }
  

function EduHomeFunc() {
  return (
	<EduLogin />
  );
}


function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  )
}



export function SideMenu(props) {

const DispMenu = () => {

const data = [
  {
    id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
    title: <Text style={[stylesCommon.TextSectionContent,]}><Link to="/">Home</Link></Text>,
  },
  {
    id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
    title: <Text style={[stylesCommon.TextSectionContent,]}><Link to="/abt">About Us</Link></Text>,
  },
  {
    id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
    title: <Text style={[stylesCommon.TextSectionContent,]}><Link to="/ConUs">Contact Us</Link></Text>,
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e29d72',
    title: <Text style={[stylesCommon.TextSectionContent,]}> Services </Text>,
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e29d72',
    title: <Text style={[stylesCommon.TextSectionContent,styles.navItemStyle]}> <Link to="/eduhome">EduTeqnik</Link> </Text>,
  },
];


	return (
		<View style={styles.container} onClick={props.onClose}>
		{
		    data.map((item, i) => (
		      <ListItem
		        key={i}
		        title={item.title}
		        bottomDivider
		      />
		    ))
		}
		</View>
	);
	}

{/*	return (
				<View style={styles.container} onClick={props.onClose}>
	        		<ScrollView>

						<Text style={[stylesCommon.TextSectionHeader,styles.sectionHeadingStyle]}>
							teqnik
						</Text>
							<Text style={[stylesCommon.TextSectionContent,styles.navItemStyle]} >
								<Link to="/">Home</Link>
							</Text>
							<Text style={[stylesCommon.TextSectionContent,styles.navItemStyle]}>
								<Link to="/abt">About Us</Link>
							</Text>
						<Text style={[stylesCommon.TextSectionHeader,styles.sectionHeadingStyle]}>
							Services
						</Text>
							<Text style={[stylesCommon.TextSectionContent,styles.navItemStyle]}>
								<Link to="/eduhome">EduTeqnik</Link>
							</Text>

					</ScrollView>
		 		</View>
	);
	}

*/}

	return (

		<View>
			<Router>

		        {/*
		          A <Switch> looks through all its children <Route>
		          elements and renders the first one whose path
		          matches the current URL. Use a <Switch> any time
		          you have multiple routes, but you want only one
		          of them to render at a time
		        */}

		        <Switch>
		          <Route exact path="/">
		            <HomeFunc />
		          </Route>
		          <Route path="/abt">
		            <AbtFunc />
		          </Route>
		          <Route path="/ConUs">
		            <ContactUsFunc />
		          </Route>
		          <Route path="/eduhome">
		            <EduHomeFunc />
		          </Route>
		          <Route path="/dashboard">
		            <Dashboard />
		          </Route>
		        </Switch>

				{ props.show &&
				<DispMenu  prp={props.show}/>
				}


    		</Router>

		</View>

    );

  }

//SideMenu.propTypes = {
//  navigation: PropTypes.object,
//  onClose: PropTypes.func.isRequired,
//  show: PropTypes.bool,
//  children: PropTypes.node
//};


const styles = StyleSheet.create({
  container: {
	position: 'absolute',
	backgroundColor: 'rgba(256, 256, 256, 0.8)',
	width: '100' ,
    paddingTop: 0,
    flex: 1
  },
  navItemStyle: {
	paddingHorizontal: 25
  },
  sectionHeadingStyle: {

  },
  footerContainer: {
    padding: 25,
    backgroundColor: 'lightgrey'
  }

});
