import React, { Component } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { Card, Button, } from 'react-native-elements'
import {stylesCommon} from '../common/MyWebElements';

export function MaterialCardWithoutImage(props) {
  return (
	
   <View style={[props.style]}>

		<Card
		  title={props.cardProp.cardTitle}>
		  <Text style={[{marginBottom: 10, textAlign: 'justify' }, stylesCommon.TextSectionContent]}>
		    {props.cardProp.cardMessage}
		  </Text>
		  <Button type= "clear"
		    buttonStyle={{borderRadius: 0, marginBottom: 5, marginBottom: 5,}}
		    title='Learn More' />
		</Card>

    </View>
  );
}

const styles = StyleSheet.create({

});

//export default MaterialCardWithoutImage;
